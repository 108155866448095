@import "src/styles/abstracts/breakpoints";
@import "src/styles/abstracts/variables";

.section {
  box-sizing: border-box;
  max-width: 1800px;
  min-height: 100vh;
  padding: 10rem 3rem 3rem;
  width: 100%;

  .error-icon {
    height: auto;
    max-width: 35rem;
    width: 100%;
  }

  .button {
    background-color: #fff;
    border: 2px solid #e8e8e8;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    padding: 0.6rem 0;
    width: 5.5rem;

    &.alt {
      background-color: #000;
      border-color: #000;
      color: #fff;
    }
  }

  .background {
    height: auto;
    left: 0;
    position: absolute;
    top: 10rem;
    width: 100%;
    z-index: -1;

    @include media-breakpoint-down(xxl) {
      top: 15rem;
    }

    @include media-breakpoint-down(xl) {
      top: 20rem;
    }

    @include media-breakpoint-down(lg) {
      top: 25rem;
    }

    @include media-breakpoint-down(md) {
      bottom: 0;
      top: unset;
    }
  }
}
